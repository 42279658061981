<template>
  <div>
    <Pane :bottom="0" :showRemarks="false">
      <a-tabs defaultActiveKey="0" :tabBarStyle="{
                margin: '0',
              }">
        <a-tab-pane key="0" :tab="stageName"> </a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <div class="export-download">
          <a-dropdown v-if="isView">
            <div class="button">
              <img :src="exportImage" alt="" />
              <span>导出文档</span>
            </div>
            <div slot="overlay" class="content">
              <div v-for="(item, index) in templateList" :key="index"><span @click="exportForm(item)">《{{ item }}》</span>
              </div>
            </div>
          </a-dropdown>
          <a-dropdown>
            <div class="button">
              <img :src="downloadImage" alt="" />
              <span>下载模板</span>
            </div>
            <div slot="overlay" class="content">
              <div v-for="(item, index) in templateList" :key="index"><span @click="download(item)">《{{ item }}》</span>
              </div>
            </div>
          </a-dropdown>
        </div>
      </template>
    </Pane>

    <div class="container">
      <a-form :form="form" @submit="handleSubmit" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" :colon="false">
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="当前项目" class="default-highlight">
              <a-input :disabled="true" :placeholder="designCode"></a-input>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="工程名称" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" class="default-highlight">
              <a-input :disabled="true" :placeholder="projectName" />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="合同谈判记录" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <a-textarea :auto-size="{ minRows: 5 }" v-decorator="[
                                'logEval',
                              ]" />
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <span slot="label" class="">附件</span>
              <div class="link-list" v-decorator="[
                                'file',
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: '请上传附件！',
                                    },
                                  ],
                                },
                              ]">
                <a-checkbox-group :value="selectedFileList" @change="onSelectFile" v-if="fileList.length !== 0">
                  <div class="link" v-for="item in fileList" :key="item.id">
                    <a-checkbox :value="item.id">
                      <a target="_blank" download :href="item.completePath">{{
                                              item.name
                                              }}</a>
                    </a-checkbox>
                  </div>
                </a-checkbox-group>
                <div style="color:#ccc;" v-else-if="fileList.length === 0 && id">
                  无
                </div>
                <div style="color:#ccc;" v-else>
                  《总承包项目采购计划调整申请表》
                </div>

                <div class="control-bar">
                  <FileUpload @uploaded="uploaded">
                    <div class="control">
                      <a-icon type="upload" />
                    </div>
                  </FileUpload>

                  <div class="control" @click="deleteFile">
                    <a-icon type="delete" />
                  </div>
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <div class="center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
            <a-button htmlType="submit" type="primary" v-if="!isView">提交</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import FileUpload from "@/components/file-upload";
import { saveAs } from "file-saver";
import { fetchDetail } from "@/api/epc";
import {
  add,
  edit,
  fetchDetailByPid,
  exportFile,
  downloadTemplate,
} from "@/api/epc/purchase";

import { mapGetters } from "vuex";

export default {
  name: "purchaseSub",
  components: {
    FileUpload,
  },
  data() {
    return {
      exportImage: require("@/assets/epc/export.png"),
      downloadImage: require("@/assets/epc/download.png"),

      stage: "epc_purchase_subpack_contract",
      isView: false,

      form: this.$form.createForm(this),
      pid: "", // 项目id
      id: "", //当前表单的id
      hid: "",
      projectName: "",
      designCode: "",

      fileList: [],
      selectedFileList: [],

      selectedUserList: [],

      templateList: ["合同谈判记录表"],
    };
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),
    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
  },
  mounted() {
    const { query } = this.$route;
    const { pid, id, hid, isView } = query || {};

    this.pid = pid;
    this.id = id;
    this.hid = hid;
    this.isView = Boolean(isView) || false;

    this.getName();
      this.getDetailByPid();
  },
  methods: {
    getDetailByPid() {
      fetchDetailByPid({ id: this.pid ,stage:this.stage})
        .then((res) => {
          if (!res.id) return;
          this.id = res.id
          this.hid = res.historyId

          this.form.setFieldsValue({
            logCost: res.logCost,
            logValue: res.logValue,
            logEval: res.logEval,
            file: res.attachmentList,
          });
          if (res.attachmentList) {
            this.fileList = res.attachmentList;
          }
        })
        .catch();
    },
    getName() {
      fetchDetail(this.pid).then((res) => {
        this.projectName = res.name;
        this.designCode = res.designCode;
      });
    },

    selectedUser(value) {
      this.selectedUserList = value;
    },

    exportForm() {
      exportFile({
        id: this.id,
        type: this.stage,
      }).then((blob) => {
        saveAs(blob, `${this.projectName}_${this.stageName}.docx`);
      });
    },
    download() {
      downloadTemplate(this.stage).then((blob) => {
        saveAs(blob, `${this.stageName}模版.docx`);
      });
    },

    onSelectContract(value) {
      console.log(value.contractAmount);
      this.form.setFieldsValue({
        contractCode: value.code,
      });
      this.form.setFieldsValue({
        contractPrice: value.contractAmount * 0.0001,
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // 如果没有id ，则新增
          delete values.file;
          if (!this.id) {
            add({
              ...values,
              pid: this.pid,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: this.fileList,
              type: this.stage,
            }).then(() => {
              this.getDetailByPid()
            });
          } else {
            edit({
              ...values,
              pid: this.pid,
              id: this.id,
              historyId: this.hid,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: this.fileList,
              type: this.stage,
            }).then(() => {
              this.getDetailByPid()
            });
          }
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  background-color: #fff;
  border: 1px solid #ccc;

  div {
    padding: 10px 5px;

    &:hover {
      background-color: #1890ff;
      color: #fff;
      cursor: pointer;
    }
  }

  div:last-child {
    margin-bottom: 0;
  }
}

.container {
  background-color: #fff;
  padding: 24px 20px;
}

.center {
  margin-top: 80px;
}
</style>
