import request from "@/api/request";
import download from "@/api/download";

export function add(data) {
  return request({
    url: "/market-service/epc/purchase/add",
    method: "post",
    data,
  });
}

export function edit(data) {
  return request({
    url: "/market-service/epc/purchase/update",
    method: "post",
    data,
  });
}
export function fetchList(params) {
  return request({
    url: `/market-service/epc/purchase/list/`,
    params,
  });
}

export function fetchDetail(params) {
  return request({
    url: `/market-service/epc/purchase/query/${params.id}`,
  });
}

export function fetchDetailByPid(params) {
  return request({
    url: `/market-service/epc/purchase/queryByPid/${params.id}/${params.stage}`,
  });
}

// 下载模版
export function downloadTemplate(params) {
  return download({
    url: `/market-service/epc/purchase/export/template?type=${params}`,
  });
}
// 导出
export function exportFile(data) {
  return download({
    url: `/market-service/epc/purchase/export`,
    data,
  });
}
