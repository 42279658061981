var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane',{attrs:{"bottom":0,"showRemarks":false},scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('div',{staticClass:"export-download"},[(_vm.isView)?_c('a-dropdown',[_c('div',{staticClass:"button"},[_c('img',{attrs:{"src":_vm.exportImage,"alt":""}}),_c('span',[_vm._v("导出文档")])]),_c('div',{staticClass:"content",attrs:{"slot":"overlay"},slot:"overlay"},_vm._l((_vm.templateList),function(item,index){return _c('div',{key:index},[_c('span',{on:{"click":function($event){return _vm.exportForm(item)}}},[_vm._v("《"+_vm._s(item)+"》")])])}),0)]):_vm._e(),_c('a-dropdown',[_c('div',{staticClass:"button"},[_c('img',{attrs:{"src":_vm.downloadImage,"alt":""}}),_c('span',[_vm._v("下载模板")])]),_c('div',{staticClass:"content",attrs:{"slot":"overlay"},slot:"overlay"},_vm._l((_vm.templateList),function(item,index){return _c('div',{key:index},[_c('span',{on:{"click":function($event){return _vm.download(item)}}},[_vm._v("《"+_vm._s(item)+"》")])])}),0)])],1)]},proxy:true}])},[_c('a-tabs',{attrs:{"defaultActiveKey":"0","tabBarStyle":{
              margin: '0',
            }}},[_c('a-tab-pane',{key:"0",attrs:{"tab":_vm.stageName}})],1)],1),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"当前项目"}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.designCode}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"工程名称","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.projectName}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"合同谈判记录","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'logEval',
                            ]),expression:"[\n                              'logEval',\n                            ]"}],attrs:{"auto-size":{ minRows: 5 }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("附件")]),_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'file',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: '请上传附件！',
                                  },
                                ],
                              },
                            ]),expression:"[\n                              'file',\n                              {\n                                rules: [\n                                  {\n                                    required: true,\n                                    message: '请上传附件！',\n                                  },\n                                ],\n                              },\n                            ]"}],staticClass:"link-list"},[(_vm.fileList.length !== 0)?_c('a-checkbox-group',{attrs:{"value":_vm.selectedFileList},on:{"change":_vm.onSelectFile}},_vm._l((_vm.fileList),function(item){return _c('div',{key:item.id,staticClass:"link"},[_c('a-checkbox',{attrs:{"value":item.id}},[_c('a',{attrs:{"target":"_blank","download":"","href":item.completePath}},[_vm._v(_vm._s(item.name))])])],1)}),0):(_vm.fileList.length === 0 && _vm.id)?_c('div',{staticStyle:{"color":"#ccc"}},[_vm._v(" 无 ")]):_c('div',{staticStyle:{"color":"#ccc"}},[_vm._v(" 《总承包项目采购计划调整申请表》 ")]),_c('div',{staticClass:"control-bar"},[_c('FileUpload',{on:{"uploaded":_vm.uploaded}},[_c('div',{staticClass:"control"},[_c('a-icon',{attrs:{"type":"upload"}})],1)]),_c('div',{staticClass:"control",on:{"click":_vm.deleteFile}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1)],1)])],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),(!_vm.isView)?_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("提交")]):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }